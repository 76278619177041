import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTiles from "../components/globalTiles"
import ImageTween from "../components/imageTween"
import AboutInstaGrid from "../components/aboutInstaGrid"

const About = () => {
  const lang = "en"

  return (
    <Layout lang={lang} translation="/pl/o-nas/">
      <SEO title="About" />

      <section className="home-top">
        <div className="home-top__wrapper global__padd">
          <h2>
            UNITY <br /> <span>Centre</span>
          </h2>
          <h2>Krakow's Business Complex</h2>
        </div>
        <div className="global__padd">
          <ImageTween
            src={require("../assets/images/about-top-image.jpg")}
            srcSet={require("../assets/images/about-top-image@2x.jpg")}
            alt=""
          />
        </div>
        <div className="home-top__desc global__padd">
          <div className="row">
            <div className="col-md-6">
              <h1>
                UNITY CENTRE - Krakow’s greatest multifunctional business
                complex
              </h1>
            </div>
            <div className="col-md-5 offset-md-1">
              <div className="home-top__inner">
                <p>
                  It offers best-in-class, high-efficiency infrastructure.{" "}
                  <br /> The full 50 000 SQM mixed-use neighborhood plan will
                  include ample work spaces, service areas, luxury residences,
                  hotel and welcoming public spots.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-content global__padd--bigger">
        <div className="row no-gutters about-content__row">
          <div className="col-lg-4">
            <ImageTween
              src={require("../assets/images/about-content-image-6.jpg")}
              srcSet={require("../assets/images/about-content-image-6@2x.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-8">
            <div className="about-content__inner sticky-wrapper">
              <h2>
                UNITY CENTRE has started a new period. Engage with a fascinating
                history and seize the future with us.
              </h2>
              <p>
                After 45 years since first works at 16-24 Lubomirskiego Street
                began, the construction of the tallest skyscraper in Krakow has
                finally been completed. UNITY Tower is now part of the modern
                UNITY CENTRE complex, which includes 2 more lower office
                buildings, a hotel and an apartment building.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="about-insta-grid global__padd">
        <AboutInstaGrid />
      </section>

      <section className="about-content global__padd">
        <div className="row no-gutters about-content__row">
          <div className="col-lg-5">
            <ImageTween
              src={require("../assets/images/about-content-image-1.jpg")}
              srcSet={require("../assets/images/about-content-image-1@2x.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-7">
            <div className="about-content__inner sticky-wrapper">
              <h2>Within yours a reach</h2>
              <p>
                At UNITY CENTRE everything is in one place. Your warm welcome at
                16-24 Lubomirskiego Street begins with UNITY Square where you
                will find restaurants, cafés, public space, and walking paths.
              </p>
              <p>
                The full roster of lifestyle amenities is planned from shopping
                areas and restaurants to entertainment spots and services zones.
                Quick public transportation - to all of Krakow - is made easy
                with multiple T-Lines, buses, trains and micromobility,
                supplemented by cycle lanes.
              </p>
            </div>
          </div>
        </div>

        <div className="row no-gutters about-content__row">
          <div className="col-lg-8">
            <div className="about-content__inner sticky-wrapper">
              <h2>
                A fascinating story about Polish Manhattan begins in 1968.
              </h2>
              <p>
                It was then that an architectural competition was announced for
                the designs of the building of NOT (Supreme Technical
                Organization), a scientific institution associating engineers
                and technicians.
                <br />
                <br />
                The winner was the work prepared by Zdzisław Arct’s team.
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <ImageTween
              src={require("../assets/images/about-content-image-3.jpg")}
              srcSet={require("../assets/images/about-content-image-3@2x.jpg")}
              alt=""
            />
          </div>
        </div>

        <div className="row no-gutters about-content__row flex-column-reverse flex-md-row">
          <div className="col-lg-5">
            <ImageTween
              src={require("../assets/images/about-content-image-2.jpg")}
              srcSet={require("../assets/images/about-content-image-2@2x.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-7">
            <div className="about-content__inner sticky-wrapper">
              <h2>A place with a story to tell</h2>
              <p>
                In the mid 1960’s, those coordinates on the map were decided to
                become a proud nexus of architectural vision with an urban
                sophistication. After 50 years and rich history twists, the
                UNITY CENTRE complex with UNITY Tower stands ready to make the
                bold return as an icon in the heart of the city.
              </p>
              <p>
                A contemporary take on space making the destinations strikes
                with sophistication, design-led city architecture and is
                illuminated by expressive simplicity. With strong, and
                cosmopolitan approaches, the architects aim to seize the
                opportunity to restore the building to its glory as a new high
                quality landmark for Krakow.
              </p>
            </div>
          </div>
        </div>

        <div className="row no-gutters align-items-center about-content__row">
          <div className="col-lg-7">
            <div className="about-content__inner sticky-wrapper">
              <p>
                The architects proposed an impressive 92-meter high skyscraper
                towering over the city. The project was created with an unusual
                scale for those times - the skyscraper was to have an area of
                ​​20,000 SQM, a congress hall, exhibition space, hotel, a
                gastronomic complex and even an underground parking.
                Unfortunately, due to lack of funds, construction works stopped
                in 1979. The specter of uncertainty hung over the skyscraper.
                The building began to be called a “skeleton”.
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <ImageTween
              src={require("../assets/images/about-content-image-4.jpg")}
              srcSet={require("../assets/images/about-content-image-4@2x.jpg")}
              alt=""
            />
          </div>
        </div>

        <div className="row no-gutters about-content__row flex-column-reverse flex-md-row">
          <div className="col-lg-5">
            <ImageTween
              src={require("../assets/images/about-content-image-5.jpg")}
              srcSet={require("../assets/images/about-content-image-5@2x.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-7">
            <div className="about-content__inner sticky-wrapper">
              <p>
                In 2005 Treimorfa Projekt (in which the local developer GD&K
                Group got shares) became the owner of “Szkieletor”. However,
                anyone who suspected that the history of the PRL skyscraper was
                about to reach its happy end at this point might be surprised.
                The new owner did not mean the end of problems. Despite the
                interesting idea for the development of the building, numerous
                disputes that lasted over the years began. The protests of the
                conservator and environmentalists, who refused to raise the
                building, as well as the local community, fearing the shading of
                the neighboring plots of land, turned out to be an inflammatory
                factor.
              </p>
              <p>
                As the years passed, many ideas for completing the construction
                appeared. Some of them were just too visionary to be completed.
                The one concept striking for success was a design of the German
                architect Hans Kollhoff. The project assumed that the entire
                skyscraper would be covered with a stone facade and raised by
                several storeys. Later on the compex was named UNITY CENTRE.
              </p>
            </div>
          </div>
        </div>

        <div className="row no-gutters about-content__row">
          <div className="col-lg-7">
            <div className="about-content__inner sticky-wrapper">
              <h2>Architecture & design</h2>
              <p>The contemporary take on expressive simplicity</p>
              <p>
                Built in steel and stone, the structure of UNITY CENTRE draws
                upon the pre-war traditions of Krakow’s architecture. Designers
                from DDJM Studio take their inspiration from historic
                achievements of eclectic development and Art Déco style.
              </p>
              <p>
                The architecture enchants with a geometrical symmetry, spatial
                dyscypline where form follows functio, striving for perfection
                and expressive simplicity, balancing the local architectural
                tradition, internationalism rhythms in designs and avant garde
                aesthetics.
              </p>
              <p>
                If we consider what a facility intended as an icon of the modern
                business centre that Krakow of today is should look like, we
                should at the same time remember its rich historic traditions.
                This leads to the conclusion that it must be eclectic. Similarly
                as a majority of Krakow’s significant buildings I am familiar
                with, we wanted it to be subtly inscribed in the panorama of the
                city. It was meant to be both recognisable and proud, like the
                skyscrapers of New York.
              </p>
              <p>
                Marek Dunikowski, architect
                <br /> DDJM Design Team
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <ImageTween
              src={require("../assets/images/about-content-image-8.jpg")}
              srcSet={require("../assets/images/about-content-image-8@2x.jpg")}
              alt=""
            />
          </div>
        </div>
      </section>

      <GlobalTiles lang={lang} />
    </Layout>
  )
}

export default About
